import Container from "~/components/layout/Container";
import type { HizmetListesi } from "../hizmetlerimiz+/_index";


export default function ServicesComponent({ services }: { services: typeof HizmetListesi }) {
  return (
    <div className="py-20">
      <Container className="flex-wrap">
        <h2 className="text-3xl w-full justify-center font-black font-source-sans-pro text-center text-[32px] mb-5">Hizmetlerimiz</h2>
        <p className="flex w-full justify-center text-center font-extralight font-source-sans-pro not-italic mb-10 text-[24px] font-raleway text-[#444444]">
          Al-De Mühendislik olarak, ısıtma, soğutma ve su ısıtma sistemleri ile ilgili hizmetleri sunmaktadır.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {services.map((service) => (
            <a key={service.title} href={`/hizmetlerimiz/${service.id}`} className="bg-white rounded-lg group mb-8 overflow-hidden">
              <img src={service.image} alt={service.title} className="group-hover:shadow-lg w-full h-48 object-cover" />
              <div className="p-5">
                <h3 className="text-xl font-black font-raleway text-[16px] text-[#16437c]">{service.title}</h3>
                <p className="mt-2 text-[14px] font-ibm-plex font-light text-[#333333]">{service.description}</p>
              </div>
            </a>
          ))}
        </div>
      </Container>
    </div>
  );
};