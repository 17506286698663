import Container from "~/components/layout/Container";
export default function References({ references }: { references: { name: string; logo: string; link: string }[] }) {
  return (
    <section className="py-16">
      <Container className="flex justify-between flex-wrap">
        <h2 className="text-[36px] font-bold mb-12 border-b border-gray-100 pb-4 flex justify-center w-full">Markalarımız</h2>
        {references.map((ref) => (
          <a key={ref.name} href={ref.link} target="_blank" rel="noreferrer" className="flex justify-center items-center hover:bg-gray-100 w-[calc(100%/5-10px)] mb-8 h-20 border-2 border-gray-200 rounded-lg px-3 py-1">
            <img src={ref.logo} alt={ref.name} className="w-auto object-contain h-[50px]" />
          </a>
        ))}
      </Container>
    </section>
  );
}